import * as React from 'react';
import { graphql } from 'gatsby';
import { PFBuilderComponent } from '../builder';
import { Helmet } from 'react-helmet';
import { Layout } from '../components/layout/layout';
import '@builder.io/widgets';
import '../builder';

export const pageQuery = graphql`
  query($path: String!) {
    allBuilderModels {
      page(target: { urlPath: $path }, limit: 1, options: { cachebust: true }) {
        content
      }
    }
  }
`;

export default function PageTemplate({ data }) {
  const content = data.allBuilderModels.page[0]?.content;
  return (
    <>
      <Helmet>
        <title>{content?.data.title}</title>
      </Helmet>

        <Layout>
          <PFBuilderComponent content={content} model="page" />
        </Layout>
    </>
  );
};